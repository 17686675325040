import React from "react";
import { usePageMeta } from "hooks/usePageMeta";
import Heading from "../Heading";
import styles from "./Page.module.css";

interface PageProps {
  title?: string;
  sidebar?: React.ReactNode;
  seo?: {
    title?: string | null;
  } | null;
}

const Page: React.FC<PageProps> = ({ children, sidebar, title, seo }) => {
  usePageMeta(title || seo?.title);

  return (
    <div className={styles.container}>
      <main className={styles.body}>
        <section className={styles.bodySection} aria-labelledby="page-title">
          {title && (
            <h1 id="page-title" className={styles.pageHeading}>
              {title}
            </h1>
          )}
          {children}
        </section>
      </main>
      {sidebar && <aside className={styles.sidebar}>{sidebar}</aside>}
    </div>
  );
};

interface TitleProps {
  additionalClasses?: string;
}

export const PageTitle: React.FC<TitleProps> = ({ children, additionalClasses }) => {
  return (
    <Heading tag="h2" className={`${styles.title} ${additionalClasses}`}>
      {children}
    </Heading>
  );
};

export const SidebarTitle: React.FC = ({ children }) => {
  return (
    <Heading tag="h3" className={styles.sidebarTitle}>
      {children}
    </Heading>
  );
};

export default Page;
