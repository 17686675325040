import React from "react";
import Login from "components/Login";
import Layout from "components/common/Layout";
import { withEnhancedServerSideProps } from "helpers/withEnhancedProps";

function LoginPage() {
  return (
    <Layout>
      <Login />
    </Layout>
  );
}

export default LoginPage;

export const getServerSideProps = withEnhancedServerSideProps(async () => ({
  props: {
    allowed: ["guest"],
  },
}));
